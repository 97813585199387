<template>
  <div>
    <section>
      <popup :popupShow="popupShow"></popup>

      <v-row class="ml-lg-15 p-5 px-md-0 mx-md-3 mr-lg-15">
        <v-col   lg="6" class="radious-grey-bg mr-5">
          <v-row>
            <v-col  sm="6" md="6"  cols="12" class="pb-0 pt-0 text-center text-lg-left text-sm-left text-md-left" >
              <h4>
                <strong>{{ name }} </strong>
              </h4>
            </v-col>
            <v-col  sm="6" md="6" cols="12" class="pb-0 pt-0 text-center text-lg-right text-sm-right text-md-right">
              <span>Last update on {{ updatedDate | formatDate }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col  sm="1" cols="12" class="text-center text-lg-left text-sm-left text-md-left">
              <v-avatar size="50">
                <v-img :src="profile"></v-img>
              </v-avatar>
            </v-col>
            <v-col  sm="5" cols="12" class="pl-7 pt-5 text-center text-lg-left text-sm-left text-md-left">
              <span
                 align-v="center" class=""><strong class="font-size-20 text-capitalize">{{ company_name }}</strong></span
              ><br />

              <span
                class="font-size-8"
                v-for="(service_items, service_index) in company_serviceline"
                :key="service_index"
                >{{ service_items }}<br
              /></span>
            </v-col>
            <v-col  sm="6" md="6" cols="12" class="text-center text-lg-right text-sm-right text-md-right pt-5" v-if="companySlug">
              <router-link :to="'/getlisted/' + companySlug"
                ><v-btn color="dark" class="" dark>
                  Update Company Profile
                </v-btn></router-link
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col  sm="6" md="6">
              <v-row v-if="website" class="text-center pb-3">
                <v-icon size="30">mdi-web</v-icon>
                <span class="font-size-14"><a :href="website" class="text-decoration-none">{{ website }}</a></span>
              </v-row>
              <v-row v-if="email" class="text-center pb-3">
                <v-icon size="30">mdi-email-outline</v-icon>
                <span class="font-size-14"><a :href="'mailto:'+email" class="text-decoration-none">{{ email }}</a></span>
              </v-row>
              <v-row class="text-center" v-if="admin_phone_number">
                <v-icon size="30">mdi-phone</v-icon>
                <span class="font-size-14"><a :href="'tel:'+admin_phone_number" class="text-decoration-none">{{ admin_phone_number }}</a></span>
              </v-row>
            </v-col>
            <v-col  sm="6" md="6">
              <v-img class="border-radius-8" src="/image/Location.png"></v-img>
            </v-col>
          </v-row>
        </v-col>

        <v-col  sm="12" md="12" lg="5" class="radious-grey-bg cust-width ">
          <v-row>
            <v-col cols="12" sm="4" lg="4" class="pt-0 mr-8">
              <h4 class="pt-0"><strong>Status</strong></h4>
            </v-col>
            <v-col cols="12" sm="3" lg="3" class="pt-0"> </v-col>
            <v-col cols="12" sm="4" lg="3" class="pt-0 ml-3">
              <v-btn class="pt-0">
                <v-icon color="green"  class="ml-0 mr-1"> mdi-circle </v-icon>
                Published
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="p-3">
            <v-col
              cols="12"
              sm="3"
              class="text-center ml-lg-5 bg-none pt-5 border-radius-8"
            >
              <v-avatar color="brown">
                <v-icon dark> mdi-eye </v-icon> </v-avatar
              ><br />
              <p class="pt-5 mb-0"><b>0</b></p>
              <br />
              <p>
                Profile <br />
                View
              </p>
            </v-col>
            <v-col cols="12" sm="1" class="p-sm-10 p-md-0 p-lg-0"></v-col>
            <v-col
              cols="12"
              sm="3"
              class="text-center bg-none pt-5 border-radius-8"
            >
              <v-avatar color="brown">
                <v-icon dark> mdi-cursor-default-outline </v-icon> </v-avatar
              ><br />
              <p class="pt-5 mb-0"><b>0</b></p>
              <br />
              <p>Click <br />through</p>
            </v-col>
            <v-col cols="12" sm="1" class="p-sm-10 p-md-0 p-lg-0"></v-col>
            <v-col
              cols="12"
              sm="3"
              class="text-center bg-none pt-5 border-radius-8"
            >
              <v-avatar color="brown">
                <v-icon dark> mdi-pulse </v-icon> </v-avatar
              ><br />
              <p class="pt-5 mb-0"><b>0</b></p>
              <br />
              <p>Top Click <br />through source</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <section class="mr-lg-15 ml-lg-15 p-5 px-md-0 mx-md-3">
        <v-row>
          <v-col cols="12" sm="10" md="12" class="mr-15 pt-0">
            <h4><b>Reviews</b></h4>
          </v-col>
          <!-- <v-col
            cols="12"
            sm="1"
            class="home-tab cust-height pt-2 pb-2 ml-5"
            v-if="userReview.length > 0"
          >
            <v-card
              ><b>{{ userReview.length }}</b> Reviews</v-card
            >
          </v-col> -->
        </v-row>
        <v-row class="ml-2 ">
          <!-- <v-col cols="12" sm="5" class="cust-width mr-5">
            <v-row class="border-radius-8 bg-none p-2 pb-0">
              <v-col cols="12" sm="2">
                <v-avatar size="60" color="brown">
                  <v-icon dark> mdi-account-multiple-outline </v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="12" sm="8" class="pt-4 pl-0">
                <h5 class="mb-0"><strong>Reference</strong></h5>
                <p>Add Reference to start</p>
              </v-col>
              <v-col cols="12" sm="2" class="pt-7 pl-0">
                <router-link :to="'/reference/' + companySlug"
                  ><v-btn color="black" class="pt-0 white--text">
                    Go
                    <v-icon class="ml-0 mr-1"> mdi-chevron-right </v-icon>
                  </v-btn></router-link
                >
              </v-col>
            </v-row>
          </v-col> -->

          <v-col cols="12" sm="12" md="12" lg="6" class="cust-width mr-5">
            <v-row class="border-radius-8 bg-none p-2 pb-0">
              <v-col cols="12" sm="2" md="2"  class="text-center">
                <v-avatar size="60" color="brown">
                  <v-icon dark> mdi-account-multiple-outline </v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="12" sm="8" md="8" class="pt-4 pl-0 text-center  text-lg-left">
                <h5 class="mb-0"><strong>Ask Question</strong></h5>
                <p>Add Question to start</p>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="pt-7 pl-0 text-center ">
                <router-link :to="'/askquestion/' + companySlug"
                  ><v-btn color="black" class="pt-0 white--text">
                    Go
                    <v-icon class="ml-0 mr-1"> mdi-chevron-right </v-icon>
                  </v-btn></router-link
                >
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="5" class="cust-width">
            <v-row class="border-radius-8 bg-none p-2 pb-0">
              <v-col cols="12" sm="2" class="text-center">
                <v-avatar size="60" color="brown">
                  <v-icon dark> mdi-account-multiple-outline </v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="12" sm="8" class="pt-4 pl-0 text-center text-lg-left">
                <h5 class="mb-0"><strong>Reviews</strong></h5>
                <p>Share Review Online</p>
              </v-col>
              <v-col cols="12" sm="2" class="pt-7 pl-0 text-center">
                <router-link :to="'/review/' + companySlug"
                  ><v-btn color="black" class="pt-0 white--text">
                    Go
                    <v-icon class="ml-0 mr-1"> mdi-chevron-right </v-icon>
                  </v-btn></router-link
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pt-8 mr-1" v-if="userReview.length > 0">
          <v-col cols="12" sm="11">
            <h4>List of Reviews</h4>
          </v-col>

          <v-col cols="12" sm="1" class="home-tab cust-height">
            <v-card class="text-center"
              ><b>{{ userReview.length }}</b> Reviews</v-card
            >
          </v-col>
        </v-row>
        <v-row
          v-for="(review, index) in userReview"
          :key="index"
          class="border-with-radious mr-5 ml-1 pt-4"
        >
          <v-col cols="12" sm="1" class="pl-5">
            <v-avatar size="60" v-if="review.company ">
              <img v-bind:src="review.company.company_logo" alt="John" />
            </v-avatar>
          </v-col>
          <v-col cols="12" sm="6" class="mr-15">
            <p class="brown--text mb-0">{{ review.company_name }}</p>
            <p class="mb-0" v-if="review.company && review.company.tag_line">{{ review.company.tag_line }}</p>
            {{ review.rating }}
            <b-form-rating
              :value="review.rating"
              class="myrating pl-0"
              readonly
              inline
            ></b-form-rating>
          </v-col>
          <v-col cols="12" sm="4" class="text-right">
            <p class="font-size-14">
              Reviewed on {{ review.updated_at | formatDate }}
            </p>
          </v-col>
          <!-- <v-col cols="12" sm="1" class="pt-2 pb-2 home-tab cust-height">
            <p class="mb-0">
              <v-icon class="ml-0 mr-1"> mdi-share </v-icon>Share
            </p>
          </v-col> -->
         <v-row class="pt-2 pl-5">
            <p>
              <strong>{{ review.review }}</strong>
            </p>
            <p>
              {{ review.company_background }}
            </p>
          </v-row>
          <hr class="mt-3 mb-3" />
          <v-row class="pl-5">
            <v-col cols="12" sm="6" class="pr-0">
              <p class="font-size-14 mb-1">Services</p>
              <p>
                <b>{{ review.type_company_work_for }}</b>
              </p>
            </v-col>

            <v-col cols="12" sm="3"></v-col>
            <v-col cols="12" sm="3" class="text-right">
              <b-button
                v-b-toggle="'collapse' + review.id"
                class="m-1"
                style="
                  display: inline-flex;
                  padding-left: 8px;
                  padding-right: 0px;
                "
                >View Full Review
                <v-icon color="white">mdi-chevron-down</v-icon></b-button
              >
            </v-col>
          </v-row>
           <b-collapse :id="'collapse' + review.id" class="">
            <b-card class="border cust-card mr-5 ml-1 pt-0">
              <v-row class="pt-0">
                <v-col
                  cols="12"
                  sm="4"
                  class="border-end bg-review text-center pr-0" v-if="review.company"
                >
                  <span class="font-size-14 text-black-50">THE COMPANY</span>
                  <h4></h4>
                  <b-avatar
                    v-if="review.company && review.company.company_logo"
                    class=""
                    size="60"
                    :src="review.company.company_logo"
                  ></b-avatar>
                  <b-avatar
                    v-if="!review.company.company_logo"
                    src="/public/noImage.png"
                  ></b-avatar> 
                 <p class="mb-1 fw-bold" >{{ review.company.company_name }}</p>
                  <p class="mb-1 ml-2 font-size-14" >
                    {{ review.company.primary_email }}
                  </p>
                  <p class="mb-1 ml-2 font-size-14" >
                    {{ review.company.website }}
                  </p>
                </v-col>
                <v-col cols="12" sm="5" class="pb-0 border-end">
                  <p class="font-size-14 text-center mb-0 text-black-50 pl-5">
                    PROJECT DETAILS
                  </p>
                  <hr />

                  <h5 class="text-center pt-2">{{ review.project_title }}.</h5>
                  <v-row class="pl-4 ml-1">
                    <v-col cols="12" class="p-1 text-right" sm="3"
                      ><span class="text-black">Project Type: </span></v-col
                    >
                    <v-col cols="12" class="p-1" sm="8"
                      ><p class="font-size-14 mb-0 text-black-50 pl-5">
                        {{ review.project_type }}
                      </p></v-col
                    >
                  </v-row>
                  <v-row class="pl-4 ml-1">
                    <v-col cols="12" class="p-1 text-right" sm="3"
                      ><span class="text-black">Start Time: </span></v-col
                    >
                    <v-col cols="12" class="p-1" sm="8"
                      ><p class="font-size-14 mb-0 text-black-50 pl-5">
                        {{ review.project_start | formatDate }}
                      </p></v-col
                    >
                  </v-row>
                  <v-row class="pl-4 ml-1">
                    <v-col cols="12" class="p-1 text-right" sm="3"
                      ><span class="text-black">End Time: </span></v-col
                    >
                    <v-col cols="12" class="p-1" sm="8"
                      ><p class="font-size-14 mb-0 text-black-50 pl-5">
                        {{ review.project_end | formatDate }}
                      </p></v-col
                    >
                  </v-row>
                  <v-row class="pl-4 pb-3 ml-1">
                    <v-col cols="12" class="p-1 text-right" sm="3"
                      ><span class="text-black">Website URL: </span></v-col
                    >
                    <v-col cols="12" class="p-1" sm="8"
                      ><p class="font-size-14 mb-0 text-black-50 pl-5">
                        {{ review.website_url }}
                      </p></v-col
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" sm="3">
                  <p
                    class="
                      font-size-14
                      text-center
                      mb-0
                      mt-1
                      text-black-50
                      pl-5
                    "
                  >
                    DETAIL RATING
                  </p>

                  <hr class="mt-3 mb-1" />
                  <v-row>
                    <v-col cols="12" sm="6" class="pb-0">
                      <p>Quality:</p>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-right pb-0">
                      <p>{{ review.company_solution_1 }}.0</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <p>Ability:</p>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-right pb-0 pt-0">
                      <p>{{ review.company_solution_3 }}.0</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                      <p>Reliability:</p>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-right pb-0 pt-0">
                      <p>{{ review.company_solution_2 }}.0</p>
                    </v-col>
                  </v-row>
                  <hr class="mb-3" />
                  <v-row>
                    <v-col class="text-center p-0">
                      <b-form-rating
                        class="myrating"
                        inline
                        :value="review.rating"
                      ></b-form-rating
                    ></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </b-card>
          </b-collapse> 
        </v-row>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import popup from "../../components/CompanyRegisterPopup.vue";
export default {
  components: { popup },
  name: "Dashboard",

  data: function () {
    return {
      website: null,
      name: null,
      profile: null,
      items: [],
      popupShow: false,
      show: 0,
      company_serviceline: [],
      Sort_By: null,
      rating: 3,
      first_name: null,
      last_name: null,
      email: null,
      phone_number: null,
      company_name: null,
      admin_phone_number: null,
      company_webiste: null,
      industry: null,
      headquater_city: null,
      company_size: null,
      job_title: null,
      project_title: null,
      cost_range: null,
      date: null,
      date1: null,
      updatedDate: null,
      menu: null,
      menu1: null,
      companySlug: null,
      id: null,
      flag: null,
      company_id: null,
      userReview: [],
    };
  },

  created() {
    if (this.$route.params.id) {
      this.getUserById();
    }
  },
  computed: {
    token() {
      return localStorage.getItem("popupshowkey");
    },
  },
  methods: {
    ...mapActions(["setUserData", "getUserReview"]),

    getUserById() {
      let data = { id: this.$route.params.id };
      this.setUserData(data).then((response) => {
        //console.log(response);
        this.id = response.data.id;
        // console.log(this.id);
        this.email = response.data.email;
        this.company_id = response.data.company_id;
        // console.log(this.company_id);
        // if (this.company_id === null) {
        //   this.popupShow = { popupShow: true };
        // }

        this.name =
          response.data.userdetails.first_name +
          " " +
          response.data.userdetails.last_name;
        this.profile = response.data.userdetails.profile_picture;
        this.company_serviceline = response.data.serviceline;
        if (response.data.company_slug) {
          this.company_name = response.data.company_slug.company_name;
          this.admin_phone_number =
            response.data.company_slug.admin_phone_number;
          this.website = response.data.company_slug.website;
          this.updatedDate = response.data.company_slug.updated_at;
          this.companySlug = response.data.company_slug.slug;
        }
        this.getUserReview(this.id).then((res) => {
          this.userReview = res.data.data;
        });
      });
    },
  },
};
</script>
<style scoped>
.radious-grey-bg {
  background-color: whitesmoke;
  padding: 15px;
  border-radius: 10px;
}
.cust-width {
  min-width: 48% !important;
}
.cust-height {
  height: 1% !important;
}
.border-with-radious {
  border: 1px solid whitesmoke !important;
  border-radius: 10px !important;
}
.form-control {
  background-color: unset !important;
  padding: 0.13rem !important;
}
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px !important;
}
.v-input__append-inner {
  margin-top: 9px !important;
}
</style>